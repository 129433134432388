<template>
	<div>
    
    <div class="dashboard__container pt-4">
      <Loader v-if="performingRequest && (!this.eventUsers || this.eventUsers.length == 0)" />
      <!-- <Loader v-if="!eventShiftsDays || eventShiftsDays.length == 0" /> -->

      <div class="dashboard__container--header" v-if="(eventShifts && eventShifts.length > 0) && (!eventInfo.workerType || eventInfo.workerType != 'Employees')">
        <div class="whiteBack mb-5" style="width:50%; min-width: 20rem;">
          <label for="pickDay" class="mb-2">Choose Day or Start Date:</label>
          <v-select
            label="title" 
            :options="eventShiftsDays"
            clearable
            v-model="activeDay"
            @input="setActiveDay"
            >
            <template #option="option">
              {{option.title | moment("dddd, MMM Do, YYYY") }}
            </template>
          </v-select>
        </div>
      </div>

        <div class="dashboard__container--body--col alt-col" style="width:100%;">
          <div v-for="shift in activeShifts" :key="shift.id" style=" padding:1.6rem; background: white; margin-bottom:1.6rem;">
            <div class="flex align-center justify-space-between mb-1">
              <span>
                <h4 v-if="!shift.type || (shift.type && (shift.type != 'Permanent' && shift.type != 'Job'))"><span v-if="activeDay">{{activeDay | moment("dddd, MMM Do") }}</span></h4>
                <!-- <h4 v-if="shift.type && (shift.type == 'Permanent' || shift.type == 'Job')">Permanent Position</h4> -->
                <h4 class="caption strong" v-if="shift.title">{{shift.title}}<span v-if="shift.poNumber"> // PO: {{shift.poNumber}}</span></h4>
                <div class="caption" v-if="shift.name">Application Status: <span class="strong" v-bind:class="{ 'danger': shift.eventStatus && shift.eventStatus == 'Closed', 'success': !shift.eventStatus || shift.eventStatus == 'Open'}">{{shift.eventStatus || 'Open'}}</span></div>
                <!-- <div class="caption caption" v-if="shift.title">{{shift.title}}<span v-if="shift.poNumber"> || {{shift.poNumber}}</span></div> -->
                
                <!-- <div class="caption" v-if="shift.id">{{shift.id}}</div> -->
                <div class="caption" v-if="shift.position">Default Position: <span class="strong">{{shift.position.label || shift.position.title}}</span></div>
                
                <div v-if="(!shift.type || (shift.type && (shift.type != 'Permanent' && shift.type != 'Job')) && shift.startTime)" class="caption"> Default Shift Times: {{ [ shift.startTime, "HH:mm" ] | moment("hh:mm A") }}<span v-if="shift.endTime"> - {{ [ shift.endTime, "HH:mm" ] | moment("hh:mm A") }}</span></div>

                <div v-if="shift.type && (shift.type == 'Permanent' || shift.type == 'Job')" class="caption mt-2 mb-2">
                  <div v-if="shift.sundayStart && shift.sundayEnd">Sunday: {{ formatAMPM(shift.sundayStart) }} - {{ formatAMPM(shift.sundayEnd) }}</div>
                  <div v-if="shift.mondayStart && shift.mondayEnd">Monday: {{ formatAMPM(shift.mondayStart) }} - {{ formatAMPM(shift.mondayEnd) }}</div>
                  <div v-if="shift.tuesdayStart && shift.tuesdayEnd">Tuesday: {{ formatAMPM(shift.tuesdayStart) }} - {{ formatAMPM(shift.tuesdayEnd) }}</div>
                  <div v-if="shift.wednesdayStart && shift.wednesdayEnd">Wednesday: {{ formatAMPM(shift.wednesdayStart) }} - {{ formatAMPM(shift.wednesdayEnd) }}</div>
                  <div v-if="shift.thursdayStart && shift.thursdayEnd">Thursday: {{ formatAMPM(shift.thursdayStart) }} - {{ formatAMPM(shift.thursdayEnd )}}</div>
                  <div v-if="shift.fridayStart && shift.fridayEnd">Friday: {{formatAMPM(shift.fridayStart) }} - {{ formatAMPM(shift.fridayEnd) }}</div>
                  <div v-if="shift.saturdayStart && shift.saturdayEnd">Saturday: {{ formatAMPM(shift.saturdayStart) }} - {{ formatAMPM(shift.saturdayEnd) }}</div>
                </div>
                <div class="mb-2">
              
                  <button class="btn btn__flat chip mt-1">{{orderedPlacedUsers(shift).length}} / {{shift.staff}}</button>

                  <button class="btn btn__flat chip mt-1 ml-2" style="color:green; border-color:green;">{{confirmedPlacedUsers(shift).length}}</button>
                </div>

              </span>
              <div>
                <button class="btn btn__icon" @click="expand(shift)" v-if="shift.collapse"><i class="fas fa-chevron-up"></i></button>
                <button class="btn btn__icon" @click="collapse(shift)" v-if="!shift.collapse"><i class="fas fa-chevron-down"></i></button>
              </div>
            </div>
            <transition name="fade">
              <div v-if="shift.collapse == true">
                
                <div>
                  <span class="caption" v-if="shift.details">Shift Details: {{shift.details}}</span>
                </div>

                <div class="pt-3">
                  <vue-good-table 
                    :columns="columns2"
                    :id="shift.id"
                    :ref="shift.id"
                    :rows="orderedPlacedUsers(shift)"
                    >
                    <template slot="table-row" slot-scope="props">

                      <span v-if="props.column.field == 'photoUrl'">
                        <span v-if="props.row.photoUrl">
                          <img :src="(props.row.photoUrl || ``)" alt="" style="width: 5rem; height:5rem; border-radius: 50%; padding: 0.25rem;">
                        </span>
                      </span>
                      <span v-else-if="props.column.field == 'fullName'">
                        <router-link :to="'/users/' + props.row.userId">
                          {{props.row.fullName}}
                          <span v-if="props.row.beeline"><i class="fa-duotone fa-bee ml-2" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                          <span v-if="props.row.lead"><i class="fa-solid fa-badge-check ml-2 blueHue"></i></span>
                        </router-link>
                      </span>

                      <span v-else-if="props.column.field == 'phone'">
                        <span v-if="props.row.phone">
                          <a :href="'sms:' + props.row.phone" class="darkLink">{{props.row.phone}}</a>
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'requestedShift'">

                        <div v-if="eventInfo.workerType && eventInfo.workerType == 'Employees'">
                          <!-- <label for="day">Start Date:</label> -->
                          <div>{{props.row.day | moment("MM/DD/YYYY")}}</div>
                          <!-- <input type="date" v-model.trim="props.row.day" id="day" @change="updateAssignment(props.row)" /> -->
                        </div>
                        <div v-else>
                          <span v-if="props.row.requestedShift && props.row.requestedShift.position && props.row.requestedShift.position.title">
                             {{props.row.requestedShift.position.title}}
                          </span>
                        </div>




                        


                      </span>

                      <span v-else-if="props.column.field == 'notes'">
                        <button class="icon mr-2 ml-2" v-if="props.row.note" v-tooltip="props.row.note">
                          <i class="far fa-sticky-note"></i>
                        </button>
                      </span>
                     <!--  <span v-else-if="props.column.field == 'moreInfo'" class="flex">
                        <div v-for="(u, index) in filteredInfo(props.row)" class="flex  flex-column" :key="index">
                          <div class="mb-1"><ContractorStatus :user="u" /></div>
                          <div><EmployeeStatus :user="u" /></div>
                        </div>
                      </span> -->


                      <span v-else-if="props.column.field == 'assignments' && (shift.type && (shift.type == 'Permanent' || shift.type == 'Job'))" class="flex">
                        <span v-if="((shift.position && shift.position.title) || (props.row.job && props.row.job.title))">
                       <!--  <span> -->

                          <button v-if="props.row.dayStatus == 'hired' && props.row.status != 'assigned'" class="btn btn__outlined btn__small" v-tooltip="'lock shift'" @click="lockJob(props, shift)"> Lock
                            <i class="fas fa-lock-open-alt ml-2" v-if="props.row.status != 'spinning'"></i>
                            <transition name="fade">
                              <span class="ml-2" v-if="props.row.status == 'spinning'">
                              <i class="fa fa-spinner fa-spin"></i>
                              </span>
                            </transition>
                          </button>
                   
                        
                          <button v-tooltip="'unlock shift'" class="btn btn__dark btn__small" v-if="props.row.dayStatus == 'hired' && props.row.status == 'assigned'" @click="unlock(props, shift)">Locked 
                            <i class="fas fa-lock-alt ml-2"></i>
                          </button>
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'assignments' && (!shift.type || (shift.type == 'Event' || shift.type == 'Gig'))" class="flex">
                        <span v-if="((shift.position && shift.position.title) || (props.row.job && props.row.job.title))">
                       <!--  <span> -->

                          <button v-if="props.row.dayStatus == 'hired' && props.row.status != 'assigned'" class="btn btn__outlined btn__small" v-tooltip="'lock shift'" @click="lockShift(props, shift)"> Lock
                            <i class="fas fa-lock-open-alt ml-2" v-if="props.row.status != 'spinning'"></i>
                            <transition name="fade">
                              <span class="ml-2" v-if="props.row.status == 'spinning'">
                              <i class="fa fa-spinner fa-spin"></i>
                              </span>
                            </transition>
                          </button>
                   
                        
                          <button v-tooltip="'unlock shift'" class="btn btn__dark btn__small" v-if="props.row.dayStatus == 'hired' && props.row.status == 'assigned'" @click="unlock(props, shift)">Locked 
                            <i class="fas fa-lock-alt ml-2"></i>
                          </button>
                        </span>
                      </span>


                      <span v-else-if="props.column.field == 'reservations'" class="flex">
                        <span v-if="
                          (props.row.dayStatus != 'hired') &&
                          (props.row.dayStatus != 'assigned') &&
                          (props.row.dayStatus != 'not requested')
                        ">
                          <button class="btn btn__outlined btn__small" @click="reserveUser(props, shift)" v-tooltip="'reserve user'">
                            Reserve 
                            <i class="far fa-calendar ml-2" v-if="!props.row.reserving"></i>
                            <transition name="fade">
                              <span class="ml-2" v-if="props.row.reserving">
                              <i class="fa fa-spinner fa-spin"></i>
                              </span>
                            </transition>
                          </button>
                        </span>
                        <span v-if="
                          (props.row.dayStatus == 'hired' || props.row.dayStatus == 'assigned')
                        " style="display:inline;">
                          <button v-tooltip="'cancel reservation'" class="btn btn__primary btn__small" @click="unreserveUser(props.row)">
                            Reserved
                            <i class="far fa-calendar-check ml-2"></i>
                          </button>
                        </span>

                        

                        <span v-if="
                          (props.row.dayStatus != 'hired') &&
                          (props.row.dayStatus != 'assigned') &&
                          (props.row.dayStatus != 'not requested')"
                          style="display:inline;">
                          <button v-tooltip="'not use this staff today'" class="btn btn__outlined-danger btn__small ml-3" @click="notRequestUser(props.row)">
                            Decline 
                            <i class="far fa-calendar-times ml-2"></i>
                          </button>
                        </span>

                        <span v-if="
                          (props.row.dayStatus == 'not requested')
                        " style="display:inline;">
                          <button v-tooltip="'cancel cancellation'" class="btn btn__danger btn__small ml-3" @click="cancelNotRequestUser(props.row)">
                            Undo  
                            <i class="far fa-calendar-times ml-2"></i>
                          </button>
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'confirmed'" style="display: flex;">
                        <button v-if="!props.row.confirmed && props.row.status == 'assigned'" class="icon" v-tooltip="'confirm'" @click="confirmPlacement(props)">
                          <i class="fas fa-check ml-2 mr-2" style="opacity: 0.4;"></i>
                        </button>

                        <!-- <button v-if="!props.row.confirmed && props.row.status == 'assigned' && (props.row.updatedRequested || (props.row.updatedRequested && Object.keys(props.row.updatedRequested).length && props.row.dayStatus != 'not requested'))" class="icon" v-tooltip="'already sent again'">
                          <i class="fa-solid fa-paper-plane ml-2 mr-2" style="opacity: 0.4;"></i>
                        </button> -->

                        <button v-if="(!props.row.confirmed && props.row.status == 'assigned'  && props.row.dayStatus != 'not requested')" class="icon" v-tooltip="'resend confirmation text'" @click="requestConfirmation(props)">
                          <!-- <i class="fa-solid fa-paper-plane ml-2 mr-2 blueHue"></i> -->

                          <span class="fa-layers fa-fw ml-2">
                            <i class="fa-solid fa-paper-plane blueHue"></i>
                            <span class="fa-layers-counter fa-xs" style="background:red; color:white; padding:0.1rem 0.25rem 0.2rem 0.25rem; border-radius: 50%;">{{props.row.updatedRequested}}</span>
                          </span>

                        </button>

                        <button v-if="props.row.confirmed" class="icon" v-tooltip="'unconfirm'" @click="unConfirmPlacement(props)">
                          <i class="fas fa-check ml-2 mr-2" style="color:green"></i>
                        </button>
                      </span>

                      
                      <span v-else-if="props.column.field == 'delete'">

                        <button class="btn btn__small btn__outlined" v-if="!props.row.showTrash && (props.row.status != 'assigned' || (!props.row.dayStatus || props.row.dayStatus == 'not requested'))" v-tooltip="'delete instance'" @click="removePlacement(props.row)">
                          Remove
                        </button>

                        <span v-if="props.row.showTrash">

                          <button class="btn btn__small ml-3 btn__outlined mr-3"  v-tooltip="'cancel'" @click="hideTrash(props)">
                            Cancel
                          </button>

                          <button class="btn btn__small btn__danger" v-tooltip="'delete instance'" @click="removePlacement(props.row)">
                            Remove
                          </button>
                        </span>
                      </span>

                      
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped type="text/css">
  .darkLink {
    color:#606266;
  }
  .darkLink:hover {
    color:#fc3ef9;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'
import firebase from 'firebase/app';
import StarRating from 'vue-star-rating'
import algoliasearch from 'algoliasearch/lite';
import ExportService from "@/services/ExportService"
import EmployeeStatus from '@/components/EmployeeStatus.vue'
import ContractorStatus from '@/components/ContractorStatus.vue'
import InfoEmailTemplate from "@/components/InfoEmailTemplate.vue";
const fb = require('../../firebaseConfig.js')

export default {
  props: ['eventInfo', 'eventShifts', 'eventShiftsDays'],
  name: 'eventClient',
  data() {
    return {
      performingRequest: false,
      spin: false,
      performingRequest7: false,
      performingRequestBulk: false,
      performingRequestAll: false,
      newActiveDay: '',
      activeDay: '',
      batch: [],
      modalValue: null,
      newGroupUser: null,
      searchClient: algoliasearch(
        '0T1SIY6Y1V',
        'f03dc899fbdd294d6797791724cdb402',
      ),
      columns2: [
        {
          field: 'photoUrl',
          sortable: false,
          width:'48px',
          tdClass: 'text-center',
        },
        {
          label: 'Name',
          field: 'fullName',
          width: '160px',
        },
        // {
        //   label: 'Status',
        //   field: 'onboardStatus',
        //   sortable: false,
        //   width:'100px',
        // },
        {
          label: 'Phone',
          field: 'phone',
          sortable: false,
          width: '120px',
        },
        // {
        //   label: '',
        //   field: 'moreInfo',
        //   sortable: false,
        //   width:'150px',
        // },
        {
          label: 'Start Date',
          field: 'requestedShift',
          width:'160px'
        },
        {
          label: 'Notes',
          field: 'notes',
          sortable: false,
          width:'60px',
          tdClass: 'text-center',
        },
        
        {
          label: 'Reserved',
          field: 'reservations',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Assigned',
          field: 'assignments',
          tdClass: 'text-center',
          sortable: false,
          width: '100px',
        },
        // {
        //   label: 'Email',
        //   field: 'email',
        //   sortable: false,
        //   tdClass: 'text-center',
        //   width: '50px',
        // },
        // {
        //   label: '',
        //   field: 'confirmed',
        //   tdClass: 'text-center',
        //   sortable: false,
        //   width: '72px',
        // },
        // {
        //   label: '',
        //   field: 'delete',
        //   tdClass: 'text-center',
        //   sortable: false,
        // },
      ]
    }
  },
  components: {
    Loader,
    StarRating,
    InfoEmailTemplate,
    ContractorStatus,
    EmployeeStatus
  },
  // created () {
  //   if (!this.eventGroupUsers || this.eventGroupUsers.length < 1) {
  //     console.log("getEventGroupUsers from eventPlacements")
  //     this.$store.dispatch("getEventGroupUsers", this.$route.params.id)
  //   }
  // },
  // watch: {
  //   '$route' (to) {
  //     this.$store.dispatch("getEventPlacementFromId", this.$route.params.id)
  //   }
  // },
  created() {

    // if (!this.eventInfo.id) {
    //   this.$store.dispatch('getEventFromId', this.$route.params.id)
    // }
    // if (this.eventInfo.id && (!this.eventShiftsDays && this.eventShiftsDays.length == 0)) {
    //   this.$store.dispatch('getEventShiftsDays', this.$route.params.id)
    // }
    // if (this.eventInfo.id && (!this.eventShifts || this.eventShifts.length < 1)) {
    //   this.$store.dispatch("getEventShifts", this.$route.params.id)
    // }
    // if ((!this.eventUsers || this.eventUsers.length < 1) && this.eventInfo.id) {
    //   this.$store.dispatch("getEventUsers", this.eventInfo)
    // }
  },
  mounted() {
    // if (this.eventShiftsDays && this.eventShiftsDays.length == 1) {
    //   this.setLoadingDay()
    // }
    this.setLoadingDay()
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'eventUsers', 'eventUsersRef', 'eventGroupUsers']),
    event() {
      return this.eventInfo
    },

    uniqueReferencedUsers() {
      return ([...new Set(this.eventUsersRef)])
      // return this.eventUsersRef.filter((obj, index) => {
      //   return this.eventUsersRef.findIndex((item) => item.id === obj.id) === index
      // })
    },
    
    filteredUsers() {
      if (this.eventInfo.workerType && this.eventInfo.workerType == 'Employees') {
        return this.eventUsers.filter(user => {
          return ((user.status != 'placed') && (user.status != 'assigned'))
        })
      
      } else if (this.activeDay) {
        return this.eventUsers.filter(user => {
          return ((user.status != 'placed') && (user.status != 'assigned') && (user.day == this.activeDay))
        })
      }
    },

    filteredPlacedUsers () {
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees')) {
      return this.eventUsers.filter(user => {
          return ((user.status == 'placed') || (user.status == 'assigned'))
        })
      } else if (this.activeDay) {
        return this.eventUsers.filter(user => {
          return (((user.status == 'placed') || (user.status == 'assigned')) && (user.day == this.activeDay))
        })
      }
    },
    filteredPlacedDayUsers () {
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees')) {
        return this.eventUsers.filter(user => {
          return ((user.status == 'placed') || (user.status == 'assigned'))
        })
      } else if (this.activeDay) {
        return this.eventUsers.filter(user => {
          return (((user.status == 'placed') || (user.status == 'assigned')) && (user.day == this.activeDay))
        })
      }
    },
    activeShifts() {
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees')) {
        return this.eventShifts
      } else if (this.activeDay) {
        return this.eventShifts.filter(shift => {
          return (shift.day == this.activeDay || shift.day.includes(this.activeDay))
        })
      }
    }
  },
  methods: {
    // setStartDay() {
    //   this.activeDay = this.eventInfo.day
    // },
    async reserveUser(props, shift) {
      props.row.reserving = true
      console.log(props.row)
      props.row.dayStatus = "hired"
      let dateObj = new Date(props.row.day);
      let month = dateObj.getUTCMonth() + 1;
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let newdate = month + "/" + day + "/" + year;
      await fb.userDaysCollection.doc(props.row.id).update({
        dayStatus: 'hired',
        dateFormat: newdate,
        sendHireNotifications:this.eventInfo.sendHireNotifications || true,
        reserving: false,
        shiftType: shift.type || 'Gig',
        shiftJob: shift.position.title,
      })
    },
    async notRequestUser(user) {
      // user.dayStatus = "not requested"
      await fb.userDaysCollection.doc(user.id).update({ dayStatus: 'not requested' })
    },
    async unreserveUser(user) {
      // user.dayStatus = null
      await fb.userDaysCollection.doc(user.id).update({
        dayStatus: null,
        dateFormat: null
      })
    },
    async cancelNotRequestUser(user) {
      // user.dayStatus = null
      await fb.userDaysCollection.doc(user.id).update({dayStatus: null})
    },
    async bulkEmail(shift) {
      this.performingRequestBulk = true
      let shiftUsers = this.orderedPlacedUsers(shift)
      let payload = {
        users: shiftUsers,
        shift: shift,
        event: this.eventInfo,
        venue: this.eventInfo.venue
      }
      console.log(payload)
      await this.$store.dispatch('sendBulkShiftEmail', payload)
      setTimeout(() => {
        this.performingRequestBulk = false;
      }, 2000)
    },
    async sendInfoEmail(props, shift) {
      props.shiftEmailSent = ({
        sent: true
      })
      await this.$store.dispatch('sendShiftEmail', {
        userDay: props,
        shift: shift,
        event: this.eventInfo,
        venue: this.eventInfo.venue
      })
    },
    showShiftModal(shift) {
      this.modalValue = shift;
    },
    closeShiftModal() {
      this.modalValue = null;
    },
    updateAssignment(row) {
      console.log(row)
      this.$store.dispatch('updateAssignment', row)
    },
    
    // showModal(user) {
    //   this.modalValue = user;
    // },
    // closeModal() {
    //   this.modalValue = null;
    // },
    // beforeOpen(event) {
    //   console.log('Event:', event)
    //   console.log('Params:', event.params)
    //   if (event.params.show === false) {
    //     event.cancel()
    //   }
    // },
    async setLoadingDay() {
      console.log('one day')

      this.performingRequest = true

      // if (!this.activeDay && (this.eventInfo && this.eventInfo.id)) {
      //   console.log('if')

      //   await this.$store.dispatch('getEventUsers', {
      //     event: this.eventInfo,
      //   })

      // } else {}

      this.performingRequest = false
    },
    async setActiveDay(day) {
      this.performingRequest = true
      this.$store.dispatch('clearEventUsersAndRef')
      if(day) {
        this.newActiveDay = day
        await this.$store.dispatch('getEventUsers', {
          event: this.eventInfo,
          day: day
        })
        // this.$store.dispatch('getEventUsersRef', {
        //   event: this.eventInfo.id,
        //   day: day
        // })
      } else {
        await this.$store.dispatch("clearEventAssignmentsByDay")
      }
      this.performingRequest = false
    },
    async messageShift(shift) {
      this.performingRequest2 = true
      let payload = {
        shift: shift,
        staff: this.orderedPlacedUsers(shift),
        message: shift.newMessage
      }
      this.$store.dispatch('updateShiftStaff', payload)
      setTimeout(() => {
        this.performingRequest7 = false;
        shift.newMessage = ''
      }, 2000)
    },
    // messageShift(shift) {
    //   this.performingRequest7 = true
    //   let payload = {
    //     shift: shift,
    //     staff: this.orderedPlacedUsers(shift),
    //     message: shift.newMessage,
    //     from: (this.userProfile.twilioNumber || null)
    //   }
    //   this.$store.dispatch('updateShiftStaff', payload)
    //   setTimeout(() => {
    //     this.performingRequest7 = false;
    //     shift.newMessage = ''
    //   }, 2000)
    // },
    addUser(item) {
      console.log(item)
      this.performingRequest = true;
      let dateObj = new Date(this.activeDay);
      let month = dateObj.getUTCMonth() + 1;
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let newdate = month + "/" + day + "/" + year;

      fb.usersCollection.doc(item.objectID).get()
      .then(doc => {
        let address = doc.data().address || null
        let blacklist = doc.data().blacklist || null
        let certs = doc.data().certs || null
        let groups = doc.data().groups || null
        let phoneVerified = doc.data().phoneVerified || null
        let photoUrl = doc.data().photoUrl || null
        let points = doc.data().points || null
        let rating = doc.data().rating || null
        let shirtsize = doc.data().shirtsize || null
        let skills = doc.data().skills || null
        let vaccination = doc.data().vaccination || null
        let firstName = doc.data().firstName || null
        let lastName = doc.data().lastName || null
        let phone = doc.data().phone || null
        let ssn = doc.data().ssn || null
        let docHold = doc.data().docHold || null
        let lead = doc.data().lead || null
        fb.userDaysCollection.add({
          address: address,
          blacklist: blacklist,
          certs: certs,
          groups: groups,
          phoneVerified: phoneVerified,
          photoUrl: photoUrl,
          points: points,
          rating: rating,
          shirtsize: shirtsize,
          skills: skills,
          vaccination: vaccination,
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          lead: lead,
          ssn: ssn,
          docHold: docHold,
          userId: item.objectID,
          firstName: item.firstName,
          lastName: item.lastName,
          phone: item.phone,
          day: this.activeDay,
          dateFormat: newdate,
          start: this.activeDay,
          preferredEvent: this.event.id,
          preferredEventName: this.event.title,
          status: "available",
          requestedShift:'',
          shift: this.activeShifts[0].id,
          requestedJob: {},
          email: item.email,
          eventSlug: this.event.slug,
          eventTitle: this.event.title,
          fullName: item.firstName + ' ' + item.lastName,
        })
      
        .then(
          doc => {
            fb.userDaysCollection.doc(doc.id).update({
            created: fb.firestore.FieldValue.serverTimestamp(),
            id: doc.id, 
          })
        })
      })
      setTimeout(() => {
        this.performingRequest = false;
      }, 250)
    },
    async lockJob(props, shift) {
      props.row.status = "spinning"
      var lockJob = firebase.functions().httpsCallable('lockJob');
      await lockJob({
        event: this.event,
        day: this.activeDay,
        row: props.row,
        shift: shift,
      })
      .then((result) => {
        props.row.status = "assigned"
        // if (!this.eventInfo.sendHireNotifications || (this.eventInfo.sendHireNotifications != false)) {
        //   this.sendInfoEmail(props.row, shift)
        // }
      })
    },
    async lockShift(props, shift) {
      props.row.status = "spinning"
      let shiftStarting
      let shiftEnding

      var lockShift = firebase.functions().httpsCallable('lockShift');
      if (shift.startTime) {
        shiftStarting = this.formatAMPM(shift.startTime)
      } else {
        shiftStarting = null
      }

      if (shift.endTime) {
        shiftEnding = this.formatAMPM(shift.endTime)
      } else {
        shiftEnding = null
      }
      await lockShift({
        event: this.event,
        day: this.activeDay,
        row: props.row,
        shift: shift,
        shiftStart: shiftStarting,
        shiftEnd: shiftEnding
      })
      .then((result) => {
        props.row.status = "assigned"
        // if (!this.eventInfo.sendHireNotifications || (this.eventInfo.sendHireNotifications != false)) {
        //   this.sendInfoEmail(props.row, shift)
        // }
      })
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    async lockAll(shift) {
      console.log(shift)
      this.performingRequestAll = true

      // this.batch.forEach(item => {
      //   console.log(item)
      // })
      
      let shiftStarting
      let shiftEnding

      var lockShiftAll = firebase.functions().httpsCallable('lockShiftAll');

      if (shift.startTime) {
        shiftStarting = this.formatAMPM(shift.startTime)
      } else {
        shiftStarting = null
      }

      if (shift.endTime) {
        shiftEnding = this.formatAMPM(shift.endTime)
      } else {
        shiftEnding = null
      }
      await lockShiftAll({
        event: this.event,
        day: this.activeDay,
        users: this.batch,
        shift: shift,
        shiftStart: shiftStarting,
        shiftEnd: shiftEnding
      })
      .then((result) => {
        this.performingRequestAll = false
      })
    },
   
    exportAll() {
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "Day",
      ]
      const exportItems = [];
      for (var key in this.eventUsers) {
        exportItems.push([
          this.eventUsers[key].firstName,
          this.eventUsers[key].lastName,
          this.eventUsers[key].email,
          this.eventUsers[key].phone,
          this.eventUsers[key].day,
          // this.dayUsers[key].address.state,
        ])
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
    },
    exportP2() {
      this.spin = true
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Phone",
        "Email",
        "Day",
        "Shift ID",
        ];
      const exportItems = [];
      console.log(this.filteredPlacedDayUsers)
      for (var key in this.filteredPlacedDayUsers) {
        let firstName = this.filteredPlacedDayUsers[key].firstName || null
        let lastName = this.filteredPlacedDayUsers[key].lastName || null
        let phone = this.filteredPlacedDayUsers[key].phone || null
        let email = this.filteredPlacedDayUsers[key].email || null
        let day = this.filteredPlacedDayUsers[key].day
        let shift = this.filteredPlacedDayUsers[key].shift
        let uid = this.filteredPlacedDayUsers[key].userId
        exportItems.push([
          firstName,
          lastName,
          phone,
          email,
          day,
          shift,
        ])

        
      }

      setTimeout(() => {
        this.$gapi.getGapiClient().then(gapi => {
            const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
            exportService.export();
          });
        this.spin = false
      }, 15000)

      
      
      
    },

    exportStaff(shift) {
      console.log(shift)
      const exportHeaders = [
        "Day",
        "First Name",
        "Last Name",
        "Event",
        "Position",
        "Start",
        "End",
        "Phone",
        "Email",
        "Points",
        "Confirmed",
        "DOB",
        "Shirt Size",
        "Code",
      ];
      const exportItems = [];
      for (var key in this.orderedPlacedUsers2(shift.id)) {
        let day = this.activeDay

        let uid = this.orderedPlacedUsers2(shift.id)[key].userId

        let confirmed = this.orderedPlacedUsers2(shift.id)[key].confirmed

        let start
        if (this.orderedPlacedUsers2(shift.id)[key].start) {
          start = this.orderedPlacedUsers2(shift.id)[key].start
        }
        if (!this.orderedPlacedUsers2(shift.id)[key].start && shift.startTime) {
          start = shift.startTime
        }
        let end
        if (this.orderedPlacedUsers2(shift.id)[key].end) {
          end = this.orderedPlacedUsers2(shift.id)[key].end
        }
        if (!this.orderedPlacedUsers2(shift.id)[key].end && shift.endTime) {
          end = shift.endTime
        }
        // let start = this.orderedPlacedUsers2(shift.id)[key].start
        // let end = this.orderedPlacedUsers2(shift.id)[key].end

        let job
        if (this.orderedPlacedUsers2(shift.id)[key].job && this.orderedPlacedUsers2(shift.id)[key].job.label) {
          job = this.orderedPlacedUsers2(shift.id)[key].job.label
        } else {
          job = (shift.position.title || null)
        }

        fb.usersCollection.doc(uid).get()
        .then(doc => {
          // console.log(doc.data())


          exportItems.push([
            day,
            doc.data().firstName,
            doc.data().lastName,
            shift.event,
            job,
            start,
            end,
            doc.data().phone,
            doc.data().email,
            doc.data().points,
            confirmed,
            moment(doc.data().dob).format('M/D/YYYY'),
            doc.data().shirtsize,
            `=REGEXEXTRACT(H2,"....$")`
          ])



          this.$gapi.getGapiClient().then(gapi => {
            const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
            exportService.export();
          })
          
        })
      }
    },
    removeAssignment(props, shift) {
      fb.userDaysCollection.doc(props.row.id).update({status: "available", shift: null})
      fb.assignmentsCollection.where("shiftId", "==", shift.id).where("userId", "==", props.row.userId).get().then(function(querySnapshot) {
        querySnapshot.forEach(doc => {
          console.log(doc.id)
          fb.assignmentsCollection.doc(doc.id).delete()
        })
      })
      fb.eventStaffCollection.where("event", "==", shift.eventId).where("user", "==", props.row.userId).get().then(function(querySnapshot) {
        querySnapshot.forEach(doc => {
          fb.eventStaffCollection.doc(doc.id).delete()
        })
      })
    },
    showTrash(p) {
      console.log(p)
      p.row.showTrash = true
      fb.userDaysCollection.doc(p.row.id).update({
        showTrash: true
      })
    },
    hideTrash(p) {
      p.row.showTrash = false
      fb.userDaysCollection.doc(p.row.id).update({
        showTrash: false
      })
    },
    deleteUser(userDay) {
      fb.userDaysCollection.doc(userDay.id).delete()
    },
    orderedUsers(shift) {
      function compare(a, b) {
        if (a.firstName < b.firstName)
          return -1;
        if (a.firstName > b.firstName)
          return 1;
        return 0;
      }
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees')) {
        return this.filteredUsers.filter(user => {
          return user.status == 'available'
        })
      } else {
        return this.filteredUsers.filter(user => {
          return user.status == 'available' && (user.day == this.activeDay)
        })
      }
      
    },
    removePlacement(row) {
      row.showTrash = false
      this.$store.dispatch('removeUserPlacement', row)
    },
    expand(shift) {
      shift.collapse = false
      this.$store.dispatch("updateEventShift", shift)
    },
    collapse(shift) {
      shift.collapse = true
      this.$store.dispatch("updateEventShift", shift)
    },

    orderedPlacedUsers2 (shift) {
      function compare(a, b) {
        if (a.firstName < b.firstName)
          return -1;
        if (a.firstName > b.firstName)
          return 1;
        return 0;
      }
      return this.filteredPlacedUsers.sort(compare).filter(user => {
        return user.shift == shift && user.day == this.activeDay
      });
    },
    orderedPlacedUsers (shift) {
      function compare(a, b) {
        if (a.firstName < b.firstName)
          return -1;
        if (a.firstName > b.firstName)
          return 1;
        return 0;
      }
      return this.filteredPlacedUsers.sort(compare).filter(user => {
        return user.shift == shift.id
      });
    },
    // orderedPlacedUsers3 (shift) {
    //   function compare(a, b) {
    //     if (a.firstName < b.firstName)
    //       return -1;
    //     if (a.firstName > b.firstName)
    //       return 1;
    //     return 0;
    //   }
    //   return this.filteredPlacedUsers.sort(compare).filter(user => {
    //     return user.shift == shift.id
    //   });
    // },
    confirmedPlacedUsers(shift) {
      function compare(a, b) {
        if (a.firstName < b.firstName)
          return -1;
        if (a.firstName > b.firstName)
          return 1;
        return 0;
      }
      return this.filteredPlacedUsers.sort(compare).filter(user => {
        return user.shift == shift.id && user.confirmed
      });
    },
    


    confirmPlacement(props) {
      fb.userDaysCollection.doc(props.row.id).update({confirmed: true})
      .then(doc => {
        fb.assignmentsCollection.where("shiftId", "==", props.row.shift).where("userId", "==", props.row.userId).get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(doc => {
            console.log(doc.data().id)
            fb.assignmentsCollection.doc(doc.data().id).update({
              confirmed: true
            })
          })
        })
      })
      props.row.confirmed = true
    },
    unConfirmPlacement(props) {
      fb.userDaysCollection.doc(props.row.id).update({confirmed: false})
      .then(doc => {
        fb.assignmentsCollection.where("shiftId", "==", props.row.shift).where("userId", "==", props.row.userId).get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(doc => {
            console.log(doc.data().id)
            fb.assignmentsCollection.doc(doc.data().id).update({
              confirmed: false
            })
          })
        })
      })
      props.row.confirmed = false
    },
    unlock(props, shift) {
      props.row.status = "spinning"
      fb.userDaysCollection.doc(props.row.id).update({
        status: "placed",
        confirmed: false,
        updatedRequested: firebase.firestore.FieldValue.delete()
      })
      setTimeout(() => {
        props.row.status = "placed"
      }, 2000)
    },
    requestConfirmation(props) {
      // props.row.updatedRequested = ({
      //   requested: true
      // })
      let newCount
      if (typeof props.row.updatedRequested === 'number') {
        newCount = props.row.updatedRequested + 1
      }
      else {
        newCount = 1
      }

      fb.userDaysCollection.doc(props.row.id).update({
        updatedRequested: newCount
      })
    },
    assignShift(shift) {
      let userId = shift.selectedStaff.userId
      let day = this.day
      let placement = {
        shiftId: shift.id,
        userId: userId,
        day: this.day
      }
      this.$store.dispatch('addPlacement', {
        id:shift.selectedStaff.id,
        placement: placement,
        shift: shift
      })
      shift.selectedStaff = null
    },
    
    filteredInfo(user) {
      return this.uniqueReferencedUsers.filter(member => {
        if (member && member.id) {
          return (member.id == user.userId)
        }
      })
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;
        console.log(`${hours}:${minutes} ${ampm}`)
      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }
      return date;
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMM Do YYYY')
      } else {
        return null
      }
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearEventUsersAndRef')
    this.spin = null
    delete this.spin
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest7 = null
    delete this.performingRequest7
    this.performingRequestBulk = null
    delete this.performingRequestBulk
    this.performingRequestAll = null
    delete this.performingRequestAll
    this.newActiveDay = null
    delete this.newActiveDay
    this.activeDay = null
    delete this.activeDay
    this.batch = null
    delete this.batch
    this.modalValue = null
    delete this.modalValue
    this.searchClient = null
    delete this.searchClient
    this.columns2 = null
    delete this.columns2
    this.activeDay = null
    delete this.activeDay
    this.newGroupUser = null
    delete this.newGroupUser
    delete this.formatDate
    delete this.reserveUser
delete this.notRequestUser
delete this.unreserveUser
delete this.cancelNotRequestUser
delete this.bulkEmail
delete this.sendInfoEmail
delete this.showShiftModal
delete this.closeShiftModal
delete this.updateAssignment
delete this.setActiveDay
delete this.messageShift
delete this.addUser
delete this.lockShift
delete this.selectionChanged
delete this.lockAll
delete this.exportAll
delete this.exportStaff
delete this.removeAssignment
delete this.showTrash
delete this.hideTrash
delete this.deleteUser
delete this.orderedUsers
delete this.removePlacement
delete this.expand
delete this.collapse
delete this.orderedPlacedUsers2
delete this.orderedPlacedUsers
delete this.confirmedPlacedUsers
delete this.confirmPlacement
delete this.unConfirmPlacement
delete this.unlock
delete this.requestConfirmation
delete this.assignShift
delete this.filteredInfo
delete this.formatAMPM
delete this.exportP2
delete this.activeShifts
delete this.filteredUsers
delete this.filteredPlacedUsers
delete this.filteredPlacedDayUsers
    console.log(this)
  }
}
</script>