<template>
  <div>
  	<!--Employee Interview-->
    <!-- <span v-if="user && user.interview && Object.keys(user.interview).length && Object.keys(user.interview).length >= 1">
      <i class="fa-solid fa-square mr-1" v-tooltip="'Employee Interview Complete'"  style="color:#21bcf3;" ></i>
    </span> -->
   <!--  <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'Employee Interview Not Complete'"></i>
    </span> -->

    <!--Verified Social / TIN-->
    <span v-if="user && user.tinVerificationState && user.tinVerificationState == 'VERIFIED'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`TIN Status ` + user.tinVerificationState" style="color:#21bcf3;"></i>
    </span>

    <span v-else-if="user && user.tinVerificationState && user.tinVerificationState == 'VERIFICATION_FAILED'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`TIN Status ` + user.tinVerificationState" style="color:#ef4b46"></i>
    </span>
    <span v-else-if="user && user.tinVerificationState && user.tinVerificationState == 'UNABLE_TO_VERIFY'">
      <i class="fa-duotone fa-square mr-1" v-tooltip="`TIN Status ` + `Not Yet Started`"></i>
    </span>
    <span v-else-if="user && user.tinVerificationState && user.tinVerificationState == 'NEEDS_VERIFICATION'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`TIN Status ` + user.tinVerificationState" style="color:#ffc800"></i>
    </span>
    <span v-else-if="user && user.tinVerificationState && user.tinVerificationState == 'SENT_FOR_VERIFICATION'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`TIN Status ` + user.tinVerificationState" style="color:#ffc800"></i>
    </span>


    
    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="`TIN Status ` + `Not Started`"></i>
    </span>

    <!--I-9 Verified-->
    <span v-if="user && user.employeeFiles && user.employeeFiles.some(item => item.fileName != 'I-9 Employment Eligibility Verification') && !user.i9Complete">
    <i class="fa-solid fa-square mr-1" v-tooltip="'Documents Uploaded to Everee'" style="color:#ffc800;"></i>
    </span>
    <span v-else-if="user && user.i9Complete">
      <i class="fa-solid fa-square mr-1" v-tooltip="'I-9 Complete'" style="color:#21bcf3;"></i>
    </span>

    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'I-9 Not Complete'"></i>
    </span>




    <span v-if="user && !user.eVerificationStatus">
      <i class="fa-duotone fa-square mr-1" v-tooltip="`E-Verification ` + `Not Started`"></i>
    </span>

    <span v-else-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Final Nonconfirmation'">
    <i class="fa-solid fa-square mr-1" v-tooltip="'E-Verify Failure'" style="color:#ef4b46"></i>
    </span>

    <span v-else-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Employment Authorized'">
    <i class="fa-solid fa-square mr-1" v-tooltip="'E-Verify Successful'" style="color:#21bcf3"></i>
    </span>

    <span v-else-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Needs More Time'">
    <i class="fa-solid fa-square mr-1" v-tooltip="'E-Verify Processing - Needs More Time'" style="color:#ffc800"></i>
    </span>

    <span v-else-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Tentative Nonconfirmation'">
    <i class="fa-solid fa-square mr-1" v-tooltip="'E-Verify Processing - Tentative Failure'" style="color:#FFA500"></i>
    </span>

    <span v-else-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Case in Continuance'">
    <i class="fa-solid fa-square mr-1" v-tooltip="'E-Verify Processing - Case in Continuance'" style="color:#ffc800"></i>
    </span>

    <span v-else-if="user && user.eVerificationStatus && user.eVerificationStatus == 'Close Case and Resubmit'">
    <i class="fa-solid fa-square mr-1" v-tooltip="'E-Verify Processing - Close Case and Resubmit'" style="color:#ffc800"></i>
    </span>





    <!--Signed Offer Letter-->
    <span v-if="user && user.letters && user.letters.length >= 1">
      <i class="fa-solid fa-square mr-1" v-tooltip="'Offer Letter Signed'" style="color:#21bcf3;"></i>
    </span>
    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'Offer Letter Not Signed'"></i>
    </span>

    <!--Health and Safety-->
    <span v-if="user && (user.employeeFiles && user.employeeFiles.some(item => item.fileName === 'Jump Staffing H&S Manual - June 2023.pdf') || user.hsmanuals && user.hsmanuals.length >= 1)">
    <i class="fa-solid fa-square mr-1" v-tooltip="'Health & Safety Signed'" style="color:#21bcf3;"></i>
    </span>
    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'Health & Safety Not Signed'"></i>
    </span>


    <!--Signed PTO-->
    <span v-if="user && user.ptos && user.ptos.length >= 1">
      <i class="fa-solid fa-square mr-1" v-tooltip="'PTO Policy Signed'" style="color:#21bcf3;"></i>
    </span>
    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'PTO Policy Not Signed'"></i>
    </span>


    <!--Heat Illness-->
    <span v-if="user && user.heatIllness && user.heatIllness.length >= 1">
      <i class="fa-solid fa-square mr-1" v-tooltip="'Heat Illness Signed'" style="color:#21bcf3;"></i>
    </span>
    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'Heat Illness Not Signed'"></i>
    </span>

    <!--COVID Training-->
    <span v-if="user && user.covidTraining && user.covidTraining.length >= 1">
      <i class="fa-solid fa-square mr-1" v-tooltip="'COVID Training Signed'" style="color:#21bcf3;"></i>
    </span>
    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'COVID Training Not Signed'"></i>
    </span>

    <!--Background Check-->
    <span v-if="user && user.backgroundCheckResponse && user.backgroundCheckResponse == 'Passed'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Background Check ` + user.backgroundCheckResponse" style="color:#21bcf3;"></i>
    </span>
    <span v-else-if="user && user.backgroundCheckResponse && user.backgroundCheckResponse == 'Needs Review'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Background Check ` + user.backgroundCheckResponse" style="color:#ffc800;"></i>
    </span>
    <span v-else-if="user && user.backgroundCheckResponse && user.backgroundCheckResponse == 'Failed Do Not Hire'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Background Check ` + user.backgroundCheckResponse" style="color:#ef4b46;"></i>
    </span>
    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'Background Check Not Completed'"></i>
    </span>

    <!--Drug Screening-->
    <span v-if="user && user.drugCheckResponse && user.drugCheckResponse == 'Passed'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Drug Screening ` + user.drugCheckResponse" style="color:#21bcf3;"></i>
    </span>
    <span v-else-if="user && user.drugCheckResponse && user.drugCheckResponse == 'Needs Review'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Drug Screening ` + user.drugCheckResponse" style="color:#ffc800;"></i>
    </span>
    <span v-else-if="user && user.drugCheckResponse && user.drugCheckResponse == 'Failed Do Not Hire'">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Drug Screening ` + user.drugCheckResponse" style="color:#ef4b46;"></i>
    </span>
    <span v-else>
      <i class="fa-duotone fa-square mr-1" v-tooltip="'Drug Screening Not Completed'"></i>
    </span>

  </div>
</template>

<script>
	export default {
		props: ['user'],
	}
</script>