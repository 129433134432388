<template>
  <div>

                   <!--Has Address-->
                  <span v-if="user && user.address">
                    <i class="fa-solid fa-square mr-1" v-tooltip="user.address.street_number + ' ' + user.address.street + ', ' + user.address.city + ', ' + user.address.state + ' ' + user.address.zip" style="color:#00C897;"></i>
                  </span>
                  <span v-else>
                    <i class="fa-duotone fa-square mr-1" v-tooltip="'No Address'"></i>
                  </span>

                  <!--Has Certs-->
                  <span v-if="user && user.certs && user.certs.length >= 1">
                    <v-popover>
                      <i class="fa-solid fa-square" v-tooltip="'Uploaded Certs'"style="color:#00C897;" ></i>
                      <template slot="popover" style="display: inline;" >
                        <span v-for="u in user.certs">{{u.type}}, </span>
                      </template>
                    </v-popover>
                  </span>
                  <span v-else>
                    <i class="fa-duotone fa-square mr-1" v-tooltip="'No Uploaded Certs'"></i>
                  </span>
                  
                  
                  <!--Self Interview-->
                  <span v-if="user && user.interview && user.interview.experience">
                    <i class="fa-solid fa-square mr-1" v-tooltip="'Self Interview Complete'" style="color:#00C897;"></i>
                  </span>
                  <span v-else>
                    <i class="fa-duotone fa-square mr-1" v-tooltip="'No Self-Interview'"></i>
                  </span>

                  <!--Sexual Harassment Training-->
                  <span v-if="user && user.address && user.address.state && user.address.state == 'CA' && user.harassmentCerts && user.harassmentCerts.length >= 1">
                    <i class="fa-solid fa-square mr-1" v-tooltip="'Sexual Harassment Training Complete'" style="color:#00C897;" ></i>
                  </span>
                  <span v-else-if="user && user.address && user.address.state && user.address.state == 'CA'">
                    <i class="fa-duotone fa-square mr-1" v-tooltip="'Sexual Harassment Training Not Complete'"></i>
                  </span>

                </div>
</template>

<script>
	export default {
		props: ['user'],
	}
</script>